import * as React from 'react';
import styles from './GiftPromo.scss';
import { t } from '~/utils/localization';
import { Button, ButtonColorPresets } from '~/components/Button/Buttons';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { isMobileOrTabletWindow, isSafari, redirectToLogin } from '~/utils/utils';
import { getSupportedWebmVideo } from '~/utils/video';
import VideoBackground from '~/components/VideoBackground/VideoBackground';
import { SANTA_GIFT_BANNER_VIDEO } from '~/Layouts/Themes/SantaPage/settings';
import { useIsDisabledAnimation } from '~/hooks/isDisabledAnimation';
import { CATEGORIES, DWH_EVENTS } from '~/const';
import dwhExport from '~/api/dwhExport';
import { markShownWelcomeGiftScreen, removeGift, takeGift } from '~/utils/gift';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import classNames from 'classnames';

interface IGiftIntroWidget {
    onClick?: () => void;
}

interface IStateSelector {
    accountId: number;
    gifts: IGifts;
    isStartedVideo: boolean;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount.id,
        gifts: state.ReducerAccount.gifts,
        isStartedVideo: state.ReducerApp.isStartedVideo,
    };
};

const wasMobilePopupOpen: Record<string, boolean> = {};

export enum GiftStatuses {
    INITIAL = 0,
    IN_PROGRESS = 1,
    ERROR = 2,
    SUCCESS = 3,
}

const GiftIntroWidget = (props: IGiftIntroWidget) => {
    const introContentRef = React.useRef<HTMLDivElement>();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const videoWasShown = React.useRef(false);
    const currentGift = state.gifts.bundles[0];
    const isGuest = !state.accountId;
    const [status, setStatus] = React.useState<number>(GiftStatuses.INITIAL);
    const [giftHover, setGiftHover] = React.useState<boolean>(false);
    const isSanta = currentGift.name.includes('ny_gift_armory');
    const isDisabledAnimation = useIsDisabledAnimation(isSanta ? CATEGORIES.SANTA_CONTAINERS : '');
    const startTime = React.useRef(Date.now());
    const timerRef = React.useRef<ReturnType<typeof setTimeout> | undefined>();

    const label = React.useMemo(() => {
        switch (status) {
            case GiftStatuses.IN_PROGRESS:
                return t('Обработка');
            case GiftStatuses.SUCCESS:
                return t('Товар получен');
            default:
                return currentGift.buttonLabel;
        }
    }, [status]);

    React.useEffect(() => {
        if (giftHover && status !== GiftStatuses.IN_PROGRESS && status !== GiftStatuses.SUCCESS) {
            introContentRef.current.classList.add(styles.hover);
        } else {
            introContentRef.current.classList.remove(styles.hover);
        }
        timerRef.current && clearTimeout(timerRef.current);

        return () => {
            timerRef.current && clearTimeout(timerRef.current);
        };
    }, [status, giftHover]);

    const onClick = async () => {
        if (giftHover && status !== GiftStatuses.INITIAL) return;
        const endTime = Date.now();
        const stopTime = Math.floor((endTime - startTime.current) / 1000);
        dwhExport.send(DWH_EVENTS.OPEN_GIFT, {
            giftName: currentGift.name,
            timeSpent: stopTime,
        });

        if (isGuest) {
            redirectToLogin();
            return;
        }

        setStatus(GiftStatuses.IN_PROGRESS);
        takeGift(currentGift).then((result) => {
            if (!result) {
                setTimeout(() => {
                    setStatus(GiftStatuses.INITIAL);
                    return;
                }, 1000);
            } else {
                setTimeout(() => {
                    setStatus(GiftStatuses.SUCCESS);
                }, 1000);
                setTimeout(close, 3500);
                setTimeout(() => removeGift(currentGift), 3500);
            }
        });
    };

    const close = () => {
        if (isGuest) {
            markShownWelcomeGiftScreen(currentGift.name);
        } else {
            updateBrowserControlState(false);
        }
        setStatus(GiftStatuses.INITIAL);
    };

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            introContentRef.current = _ref;
        }
    };

    const onMouseEnter = () => {
        if (!introContentRef.current) {
            return;
        }
        setGiftHover(true);
    };

    const onMouseLeave = () => {
        if (!introContentRef.current) {
            return;
        }
        setGiftHover(false);
    };

    if (isMobileOrTabletWindow) {
        return null;
    }

    const backgroundColor = currentGift.buttonBackgroundColor;
    const textColor = currentGift.buttonTextColor;

    const isDisabled = status !== GiftStatuses.INITIAL;

    let video: IVideo;
    if (getSupportedWebmVideo(currentGift.previewCardVideo)) video = currentGift.previewCardVideo || null;
    const withVideo = !!video && !isDisabledAnimation && !isSafari && (!state.isStartedVideo || videoWasShown.current);

    if (withVideo) videoWasShown.current = true;

    const introWrapperStyles = classNames(styles.introWrapper, {
        [styles.getGiftAmimation]: status === GiftStatuses.SUCCESS,
    });

    const introButtonsStyles = classNames(styles.introButtons, {
        [styles.noCursor]: giftHover && isDisabled,
    });

    const introContentStyles = classNames(styles.introContent, {
        [styles.noStatic]: withVideo,
    });

    return (
        <React.Fragment>
            <div className={introWrapperStyles}>
                <div className={introContentStyles} ref={setRef} style={{ backgroundImage: `url(${currentGift.icons.medium})` }}>
                    <div className={styles.animationWrapper}></div>
                    <div className={styles.introBody}>
                        <div className={styles.intoInfo} />
                    </div>
                </div>
                {withVideo && <VideoBackground video={video} className={styles.introVideo} hideStaticBackground />}
                <div className={introButtonsStyles} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    {status === GiftStatuses.SUCCESS ? (
                        <div className={styles.receivedGiftWrapper}>
                            <div className={styles.receivedGiftText}>{t('Подарок успешно получен')}</div>
                        </div>
                    ) : (
                        <Button
                            animationColor={backgroundColor || '#B23D03'}
                            className={styles.introButton}
                            isEnabledAnimation={true}
                            disabled={isDisabled}
                            backgroundColor={backgroundColor || ButtonColorPresets.ORANGE}
                            textColor={textColor}
                            label={label}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default GiftIntroWidget;
