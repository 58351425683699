import * as React from 'react';
import styles from './WsmartDoubloonsPopup.scss';
import { State } from '~/Reducers';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { IBlurView, IChangeVisiblePopup } from '~/Actions/ActionAppType';
import { blurView, changeVisiblePopup, changeVisiblePort, hidePopup } from '~/Actions/ActionApp';
import { t } from '~/utils/localization';
import { openCategoryByName } from '~/utils/category';
import WowsCommerce, { detectPlatform, PLATFORMS } from '@wg/wows-commerce';
import FastGoldProduct from '~/components/WSMart/FastGoldProduct/FastGoldProduct';
import { arrayToObjectByKey } from '~/utils/utils';
import { closePort as closeClientPort, playButtonClickSound } from '~/api/WoWsClient';
import { changeBackground } from '~/containers/PortContainer/PortContainer';
import { CATEGORIES, DWH_EVENTS } from '~/const';
import PurchaseBlock from '~/components/WSMart/PurchaseBlock/PurchaseBlock';
import LegalFooter from '~/components/WSMart/Footer/LegalFooter';
import PurchaseFooter from '~/components/WSMart/Footer/PurchaseFooter';
import classNames from 'classnames';
import { CheckboxWithLabel } from '@wg/wows-react-uikit';
import Account from '~/account/Account';
import { updateUserAdultFlag } from '~/Actions/ActionAccount';
import Currency from '~/components/Currency/Currency';
import { assets } from '@wg/wows-entities/const';
import { ClientPaymentMethod } from '@wg/wows-commerce/api/platform/web/webPreparePurchase';
import dwhExport from '~/api/dwhExport';
import { setWsmartPaymentMethod } from '~/Actions/ActionWSMart';
import { settings } from '~/utils/settings';
import { WoWsCommerceStorefrontNames } from '~/core/WowsCommerce/WoWsCommerceHelper';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { POPUPS_NAME } from '~/components/PopupManager';

interface IStateSelector {
    accountId: number;
    fastGoldProductWrappers: WsmartGoldProductWrapper[];
    fastGoldDefaultProductsWrappers: WsmartGoldProductWrapper[];
    wsmartProducts: ProductEntity[];
    fastGold: WowsCommerce;
    WowsCommerce: WowsCommerce;
    port: IPort;
    isAdult: boolean;
    wallet: IBalance;
    paymentMethod: ClientPaymentMethod;
    currentProductId: string;
    categories: ICategories;
}

interface IProps {
    data: {
        goldShortage?: number;
        groupName?: string;
    };
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount.id,
        fastGoldProductWrappers: state.ReducerWSMart.fastGoldProducts,
        fastGoldDefaultProductsWrappers: state.ReducerWSMart.fastGoldDefaultProducts,
        wsmartProducts: state.ReducerWSMart.products,
        fastGold: state.ReducerWSMart.fastGold,
        WowsCommerce: state.ReducerWSMart.wowsCommerce,
        port: state.ReducerApp.port,
        isAdult: state.ReducerAccount.isAdult,
        wallet: state.ReducerAccount?.balance,
        paymentMethod: state.ReducerWSMart.paymentMethod,
        currentProductId: state.ReducerWSMart.currentProductId,
        categories: state.ReducerApp.categories,
    };
};

const WsmartDoubloonsPopup = (props: IProps) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const dispatch = useDispatch();
    const productsInitital = [...state.fastGoldProductWrappers] || [];
    const defaultProducts = [...state.fastGoldDefaultProductsWrappers] || [];
    const balanceObject = arrayToObjectByKey(state.wallet || [], 'currency');
    const platform = detectPlatform();

    const isAvailableImmediateDeliveryValidation = settings.isImmediateDeliveryValidation[settings.realm] && platform === PLATFORMS.WEB;

    React.useEffect(() => {
        dispatch<IBlurView>(blurView(true));
        state.WowsCommerce?.checkPurchaseRestrictionByCountryMismatch();
        state.WowsCommerce?.checkPurchaseRestrictionByUserCountryIsSet(true);
    }, []);

    const close = () => {
        dispatch<IChangeVisiblePopup>(changeVisiblePopup(POPUPS_NAME.WSMART_DOUBLOONS_POPUP));
    };

    const goToPremiumShop = () => {
        playButtonClickSound();
        if (state.port?.isVisible) {
            changeBackground(false);
            closeClientPort();
            dispatch(changeVisiblePort(false, null, null));
        }

        dispatch(hidePopup());
        dwhExport.send(DWH_EVENTS.FASTGOLD_GO_PREMIUM_SHOP);
        openCategoryByName(CATEGORIES.WSMART);
    };

    let products: WsmartGoldProductWrapper[];

    if (props?.data?.goldShortage) {
        products = productsInitital.filter((value) => value.totalGoldAmount >= props.data.goldShortage);

        if (products.length < 4) {
            products = productsInitital.slice(-4);
        }
    } else {
        products = defaultProducts;
    }

    products = products.splice(0, 4).reverse();

    const isProductsAvailable = products.length < 1;

    React.useEffect(() => {
        if (!isProductsAvailable) {
            const defaultSelected = products.reverse().find((product) => product.totalGoldAmount >= props?.data?.goldShortage) || products[products.length - 1];
            dispatch(setWsmartPaymentMethod(undefined));
            state.WowsCommerce.handleChangeCurrentProduct(defaultSelected.product, WoWsCommerceStorefrontNames.FASTGOLD);
        }
    }, []);

    const changeUserAdultFlag = () => {
        Account.updateAccountSettings({ is_adult: !state.isAdult });
        dispatch(updateUserAdultFlag());
    };

    const Wallet = () => {
        return (
            <React.Fragment>
                <Currency amount={balanceObject?.[assets.GOLD].value} currency={assets.GOLD} className={styles.miniWalletCurrency} withoutAnimation />
            </React.Fragment>
        );
    };

    const style: React.CSSProperties = {
        overflowY: 'auto',
        alignItems: 'flex-start',
    };

    return (
        <Popup
            onClose={close}
            popupClassName={styles.popup}
            className={classNames(styles.popupInner, styles.popupWithFooter)}
            closeIconClassName={styles.closeIcon}
            outsideClick={false}
            renderEscButton={isProductsAvailable}
            style={style}
        >
            {isProductsAvailable ? (
                <div className={styles.storeUnavailable}>{t('Произошла ошибка. Повторите попытку позже.')}</div>
            ) : (
                <>
                    <div className={classNames(styles.popupContainer)}>
                        <div className={styles.popupHeader}>
                            <PopupHeader title={t('Покупка дублонов')} />
                            <div className={styles.miniWallet}>
                                <Wallet />
                            </div>
                        </div>
                        <PopupBody className={styles.content}>
                            <div className={styles.contentTitle}>
                                {t('Выберите желаемое количество дублонов')}
                                {state.categories?.[CATEGORIES.WSMART] && (
                                    <span className={classNames('external-link_gold', styles.contentTitleLink)} onClick={goToPremiumShop}>
                                        {t('Больше наборов')}
                                    </span>
                                )}
                            </div>
                            <div className={styles.products}>
                                {products.map((productWrapper) => {
                                    return <FastGoldProduct key={productWrapper.product.id} product={productWrapper.product} />;
                                })}
                            </div>
                            {platform === PLATFORMS.WEB && <PurchaseBlock />}
                            {isAvailableImmediateDeliveryValidation && (
                                <div
                                    className={styles.agreement}
                                    ref={(_ref) => {
                                        RefManager.setRef(RefManagerKeys.WsmartAgreement, _ref);
                                    }}
                                >
                                    <CheckboxWithLabel
                                        isChecked={state.isAdult}
                                        className={classNames({
                                            [styles.warn]: !state.isAdult,
                                        })}
                                        labelText={
                                            <span className={styles.ageRestrictionLabel}>
                                                {t(
                                                    'I hereby agree to immediate delivery of a purchased order. I will lose my right to withdraw this order since such delivery process has started except for the order to purchase Premium account.',
                                                )}
                                            </span>
                                        }
                                        onChange={changeUserAdultFlag}
                                    />
                                </div>
                            )}
                        </PopupBody>
                        <PopupFooter className={styles.footer}>
                            <PurchaseFooter isAvailableImmediateDeliveryValidation={isAvailableImmediateDeliveryValidation} />
                        </PopupFooter>
                    </div>
                    <LegalFooter />
                </>
            )}
        </Popup>
    );
};

export default WsmartDoubloonsPopup;
