import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { NotificationPopup } from '~/components/Notifications/Notifications';
import { arrayToObjectByKey } from '~/utils/utils';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';

import CouponPopup from '~/components/Popups/Coupon/CouponPopup';
import CurrenciesPopup from '~/components/Popups/CurrenciesPopup';
import ChooseCouponPopup from '~/components/Popups/ChooseCouponPopup/ChooseCouponPopup';
import ConfirmPurchase from '~/components/Popups/Purchase/ConfirmPurchase';
import PurchaseSuccessPopup from '~/components/Popups/PurchaseSuccessPopup';
import PurchaseErrorPopup from '~/components/Popups/PurchaseErrorPopup';
import DownloadGamePopup from '~/components/Popups/DownloadGamePopup';
import WSMartPurchaseResultPopup from '~/components/Popups/WSMartPurchaseResultPopup';
import AuctionAboutPopup from '~/components/Popups/Auction/AboutPopup/AuctionAboutPopup';
import BidPlacingSuccessPopup from '~/components/Popups/Auction/BidPlacingSuccessPopup/BidPlacingSuccessPopup';
import BidPlacingPopup from '~/components/Popups/Auction/BidPlacingPopup/BidPlacingPopup';
import BidPlacingErrorPopup from '~/components/Popups/Auction/BidPlacingErrorPopup/BidPlacingErrorPopup';
import IframePopup from '~/components/Popups/Iframe/IframePopup';
import BundleLimitPopup from '~/components/BundleLimitWidget/Popup/BundleLimitPopup';
import WonPopup from '~/components/Popups/Auction/WonPopup/WonPopup';
import AutoDescriptionPopup from '~/components/Popups/AutoDescription/AutoDescriptionPopup';
import WsmartDoubloonsPopup from '~/components/Popups/Wsmart/WsmartDoubloonsPopup';
import MobileTooltip from '~/components/Popups/MobileTooltip/MobileTooltip';
import RandomBundlesPurchaseSuccess from '~/components/Popups/RandomBundlePurchase/RandomBundlesPurchaseSuccess';
import ItemDescriptionPopup from './Popups/ItemDescription/ItemDescriptionPopup';
import SerialSequencePurchasePopup from '~/components/Popups/Purchase/SerialSequence/SerialSequencePurchasePopup';
import SerialSequenceSucessPurchase from './Popups/SerialSequencePurchase/SerialSequenceSucessPurchase';
import SeabattleLoaderPopup from '~/components/Popups/SeabattleLoader/SeabattleLoaderPopup';
import ForbiddenRegion from './Popups/ForbiddenRegion/ForbiddenRegion';
import TradeInConfirmation from './Popups/TradeInConfirmation/TradeInConfirmation';
import TradeInStatus from './Popups/TradeInStatus/TradeInStatus';
import TradeInRules from './Popups/TradeInRules/TradeInRules';
import MrpsExchangePopup from './Mrps/MrpsExchangePopup/MrpsExchangePopup';
import MrpsShipDescriptionPopup from './Mrps/MrpsShipDescriptionPopup/MrpsShipDescriptionPopup';
import { useEffect } from 'react';

interface IPopupManager {
    popup: IPopup;
    isPopupActive: boolean;
}

export const POPUPS_NAME = {
    COUPON_POPUP: 'coupon_popup',
    CURRENCIES_POPUP: 'CURRENCIES_POPUP',
    CHOOSE_COUPON_POPUP: 'CHOOSE_COUPON_POPUP',
    CONFIRM_PURCHASE: 'CONFIRM_PURCHASE',
    SUCCESS_PURCHASE: 'SUCCESS_PURCHASE',
    PURCHASE_RANDOM_BUNDLE_SUCCESS: 'PURCHASE_RANDOM_BUNDLE_SUCCESS',
    ERROR_PURCHASE: 'ERROR_PURCHASE',
    INFO_SCREEN_POPUP: 'INFO_SCREEN_POPUP',
    WELCOME_PAGE_POPUP: 'WELCOME_PAGE_POPUP',
    DOWNLOAD_GAME_POPUP: 'DOWNLOAD_GAME_POPUP',
    WSMART_PURCHASE_RESULT: 'WSMART_PURCHASE_RESULT',
    AUCTION_ABOUT_POPUP: 'AUCTION_ABOUT_POPUP',
    BID_SUCCESSFULLY_PLACED: 'BID_SUCCESSFULLY_PLACED',
    BID_ERROR_PLACED: 'BID_ERROR_PLACED',
    BID_PLACED_POPUP: 'BID_PLACED_POPUP',
    AUCTION_WON_POPUP: 'AUCTION_WON_POPUP',
    IFRAME_POPUP: 'IFRAME_POPUP',
    AUTO_DESCRIPTION_POPUP: 'AUTO_DESCRIPTION_POPUP',
    BUNDLE_LIMIT_INFO: 'BUNDLE_LIMIT_INFO',
    WSMART_DOUBLOONS_POPUP: 'WSMART_DOUBLOONS_POPUP',
    SANTA_AUTO_DESCRIPTION_POPUP: 'SANTA_AUTO_DESCRIPTION_POPUP',
    MOBILE_TOOLTIP_POPUP: 'MOBILE_TOOLTIP_POPUP',
    ITEM_DESCRIPTION_POPUP: 'ITEM_DESCRIPTION_POPUP',
    SERIAL_SEQUENCE_PURCHASE: 'SERIAL_SEQUENCE_PURCHASE',
    SERIAL_SEQUENCE_SUCCESS_PURCHASE: 'SERIAL_SEQUENCE_SUCCESS_PURCHASE',
    SEABATTLE_LOADER: 'SEABATTLE_LOADER',
    SHIP_DESCRIPTION: 'SHIP_DESCRIPTION',
    FORBIDDEN_REGION: 'FORBIDDEN_REGION',
    TRADEIN_CONFIRMATION: 'TRADEIN_CONFIRMATION',
    TRADEIN_STATUS: 'TRADEIN_STATUS',
    TRADEIN_RULES: 'TRADEIN_RULES',
    GIFT_ACCEPT: 'GIFT_ACCEPT',
    MRPS_POPUP: 'MRPS_POPUP',
};

interface IStateSelector {
    popups: IPopup[];
    notifications: INotification[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        popups: state.ReducerApp.popups,
        notifications: state.ReducerAccount.notifications,
    };
};

const PopupManager = (props: IPopupManager) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const arrayNotifications = state.notifications || [];
    const notifications = arrayToObjectByKey(arrayNotifications, 'name');
    const notification = notifications[props.popup?.data?.name];

    let popup: React.ReactNode;

    useEffect(() => {
        // remove focus from page elements (buttons)
        if (props.isPopupActive) (document?.activeElement as HTMLElement)?.blur?.();
    }, [props.isPopupActive, props.popup.name]);

    switch (props.popup.name) {
        case POPUPS_NAME.COUPON_POPUP:
            popup = <CouponPopup />;
            break;

        case POPUPS_NAME.CURRENCIES_POPUP:
            popup = <CurrenciesPopup />;
            break;

        case POPUPS_NAME.CHOOSE_COUPON_POPUP:
            popup = <ChooseCouponPopup />;
            break;

        case POPUPS_NAME.CONFIRM_PURCHASE:
            popup = <ConfirmPurchase />;
            break;

        case POPUPS_NAME.SUCCESS_PURCHASE:
            popup = <PurchaseSuccessPopup />;
            break;

        case POPUPS_NAME.ERROR_PURCHASE:
            popup = <PurchaseErrorPopup />;
            break;

        case POPUPS_NAME.DOWNLOAD_GAME_POPUP:
            popup = <DownloadGamePopup />;
            break;

        case POPUPS_NAME.INFO_SCREEN_POPUP:
            popup = <NotificationPopup data={notification} />;
            break;

        case POPUPS_NAME.WSMART_PURCHASE_RESULT:
            popup = <WSMartPurchaseResultPopup />;
            break;

        case POPUPS_NAME.AUCTION_ABOUT_POPUP:
            popup = <AuctionAboutPopup />;
            break;

        case POPUPS_NAME.BID_SUCCESSFULLY_PLACED:
            popup = <BidPlacingSuccessPopup data={props.popup?.data} />;
            break;

        case POPUPS_NAME.BID_ERROR_PLACED:
            popup = <BidPlacingErrorPopup />;
            break;

        case POPUPS_NAME.BID_PLACED_POPUP:
            popup = <BidPlacingPopup lot={props.popup?.data?.lot} fromLotPage={props.popup?.data?.fromLotPage} />;
            break;

        case POPUPS_NAME.IFRAME_POPUP:
            popup = <IframePopup />;
            break;

        case POPUPS_NAME.AUTO_DESCRIPTION_POPUP:
            popup = <AutoDescriptionPopup />;
            break;

        case POPUPS_NAME.BUNDLE_LIMIT_INFO:
            popup = <BundleLimitPopup />;
            break;

        case POPUPS_NAME.AUCTION_WON_POPUP:
            popup = <WonPopup />;
            break;

        case POPUPS_NAME.WSMART_DOUBLOONS_POPUP:
            popup = <WsmartDoubloonsPopup data={props.popup?.data} />;
            break;

        case POPUPS_NAME.MOBILE_TOOLTIP_POPUP:
            popup = <MobileTooltip contents={props.popup?.data?.contents} />;
            break;

        case POPUPS_NAME.PURCHASE_RANDOM_BUNDLE_SUCCESS:
            popup = <RandomBundlesPurchaseSuccess data={props.popup?.data} />;
            break;

        case POPUPS_NAME.ITEM_DESCRIPTION_POPUP:
            popup = <ItemDescriptionPopup data={props.popup?.data} />;
            break;

        case POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE:
            popup = <SerialSequencePurchasePopup data={props.popup?.data} />;
            break;

        case POPUPS_NAME.SERIAL_SEQUENCE_SUCCESS_PURCHASE:
            popup = <SerialSequenceSucessPurchase data={props.popup?.data} />;
            break;

        case POPUPS_NAME.SEABATTLE_LOADER:
            popup = <SeabattleLoaderPopup />;
            break;

        case POPUPS_NAME.SHIP_DESCRIPTION:
            popup = <MrpsShipDescriptionPopup data={props.popup?.data} />;
            break;

        case POPUPS_NAME.FORBIDDEN_REGION:
            popup = <ForbiddenRegion data={props.popup?.data} />;
            break;

        case POPUPS_NAME.TRADEIN_CONFIRMATION:
            popup = <TradeInConfirmation />;
            break;

        case POPUPS_NAME.TRADEIN_STATUS:
            popup = <TradeInStatus data={props.popup?.data} />;
            break;

        case POPUPS_NAME.TRADEIN_RULES:
            popup = <TradeInRules />;
            break;

        // временная заглушка вместо попапа с подарком
        case POPUPS_NAME.GIFT_ACCEPT:
            popup = 'some_gift_popup';
            break;

        case POPUPS_NAME.MRPS_POPUP:
            popup = <MrpsExchangePopup {...(props.popup.data || {})} />;
            break;

        default:
            return null;
    }

    return <PopupManagerContext.Provider value={props}>{popup}</PopupManagerContext.Provider>;
};

export default PopupManager;
